:root {
    --primary-color: #3498DB; /* Azul principal */
    --secondary-color: #E67E22; /* Laranja secundário */
    --primary-color-dark: #2C3E50; /* Azul escuro */
    --text-color-light: #ECF0F1; /* Branco suave para o texto */
    --background-color: #F8F9F9; /* Fundo claro */
    --text-color-dark: #2C3E50; /* Texto escuro */
    --card-background-color: #FFFFFF; /* Fundo do card */
    --border-color: #E0E0E0; /* Cor da borda */
    --shadow-color: rgba(0, 0, 0, 0.1); /* Sombra */
    --border-color-light: rgba(255, 255, 255, 0.1); /* Borda sutil */
    --secondary-color-soft: #F39C12; /* Amarelo dourado suave para títulos */


}


button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.footer {
    background-color: var(--primary-color-dark);
    color: var(--text-color-light);
}
